<template>
  <div class="reward" :class="{skeleton : !state.loaded }">
    <div class="container" v-if="$store.state.account.loggedIn">
      <div class="row">
        <div class="input col-lg-7">
          <div class="wrapper">
            <router-link :to="`/reward/${$route.params.projectSeq}`" class="back font-sm pointer" title="프로젝트 상세로 이동">
              <span class="img" style="background-image: url(/assets/ico/common.back.svg)"></span>
            </router-link>
            <router-link :to="`/reward/${$route.params.projectSeq}`" class="thumbnail img" title="프로젝트 조회" :style="{ 'background-image' : `url(${$definitions.urls.imgCdn + state.project.filePath})` }"></router-link>
            <header>
              <router-link :to="`/reward/${$route.params.projectSeq}`" class="title font-lg" title="프로젝트 조회">
                <span>{{ state.project.projectName }}</span>
              </router-link>
              <div class="builder font-sm">
                <span>{{ state.project.builderName }}</span>
              </div>
            </header>
            <div class="parts">
              <div class="part amount">
                <div class="subject font-md">
                  <label :for="`${component.name}RewardAmt`">투자 금액</label>
                  <div class="desc font-sm">
                    <span>투자 희망 금액을 선택하세요.</span>
                  </div>
                </div>
                <div class="side">
                  <select class="form-control border-focus-point" v-model="state.form.expenseAmt">
                    <option :value="a" v-for="(a, idx) in state.amounts" :key="idx">{{ $lib.getNumberFormat(a) }}</option>
                  </select>
                  <span>{{ state.contestInfo.currencyUnit }}</span>
                </div>
              </div>
              <div class="part mobile-auth" v-if="state.project.mobileAuthFlag === 'Y'">
                <div class="subject">
                  <label :for="`${component.name}Mobile`">
                    <span class="color-point">*</span>
                    <span> 연락처</span>
                  </label>
                </div>
                <div class="content">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group mb-0">
                        <MyPhone :id="`${component.name}Mobile`" :value.sync="state.mobile" placeholder="ex) 01012345678" v-if="state.loaded"/>
                        <input type="text" class="form-control" v-else/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="part cheer">
                <div class="subject font-md">
                  <label :for="`${component.name}CheerMessage`">응원 한마디</label>
                  <span class="desc font-sm">기재하지 않으시면 {{ defaultSupportComment }}로 노출됩니다.</span>
                </div>
                <div class="content">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="img profile" :style="{ backgroundImage: `url(${$store.getters.profileImageUrl()})` }"></span>
                    </div>
                    <textarea :id="`${component.name}CheerMessage`" class="border-focus-point form-control font-sm" :placeholder="`ex) ${defaultSupportComment}`" v-model="state.form.supportComment"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pay col-lg-5">
          <div class="wrapper">
            <div class="outer">
              <div class="inner">
                <div class="details">
                  <table class="table">
                    <tbody>
                    <tr>
                      <th>
                        <span>최대 한도</span>
                      </th>
                      <td>
                        <span>{{ $lib.getNumberFormat(state.contestInfo.projectPossibleLimit) }}{{ state.contestInfo.currencyUnit }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span>나의 투자</span>
                      </th>
                      <td>
                        <span>{{ $lib.getNumberFormat(state.contestInfo.myProjectInvestAmt) }}{{ state.contestInfo.currencyUnit }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span>잔여 한도</span>
                      </th>
                      <td>
                        <span>{{ $lib.getNumberFormat(state.myAvailableAmt) }}{{ state.contestInfo.currencyUnit }}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="subject font-lg clearfix">
                  <span class="float-left">투자 금액</span>
                  <span class="float-right">
                    <b class="color-point">{{ $lib.getNumberFormat(state.form.expenseAmt) }}</b>
                    <span class="color-point">{{ state.contestInfo.currencyUnit }}</span>
                  </span>
                </div>
              </div>
              <div class="guide font-sm">
                <div class="pb-2">
                  <b>유의사항</b>
                </div>
                <div class="text">
                  <div>
                    <a>1.</a>
                    <span>프로젝트의 투자 금액은 모의투자를 위한 가상금액입니다.</span>
                  </div>
                  <div>
                    <a>2.</a>
                    <span>프로젝트에 소개된 리워드는 모의투자자에게 실제로 제공되지 않습니다.</span>
                  </div>
                  <div>
                    <a>3.</a>
                    <span>모의투자 내역은 프로젝트 진행자 및 모의투자 주최, 주관사에게 제공될 수 있습니다.</span>
                  </div>
                </div>
                <div class="form-check">
                  <div>
                    <input class="form-check-input" type="checkbox" :id="`${component.name}Check`"/>
                    <label class="form-check-label" :for="`${component.name}Check`">위 내용을 확인하였고 동의합니다.</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="action">
              <button class="btn btn-block btn-point font-md" @click="submit()">모의투자하기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NoLogin v-else-if="$store.state.account.checked"/>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import Number from "@/components/Number";
import Phone from "@/components/Phone";
import Address from "@/components/Address";
import NoLogin from "@/components/NoLogin";
import CreditCard from "@/components/CreditCard";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import router from "@/scripts/router";
import store from "@/scripts/store";
import MyPhone from "@/components/MyPhone";

function Component(initialize) {
  this.name = "pageRewardOrderForm";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {MyPhone, CreditCard, NoLogin, Address, Phone, Number},
  setup() {
    const component = new Component(async () => {
      if (!store.state.account.loggedIn) {
        return store.dispatch("goLoginPage", {replace: true});
      }

      projectSeq = router.app.$route.params.projectSeq;
      state.mobile = store.state.account.mobile;

      const res1 = await http.get(`/api/reward/projects/${projectSeq}`).catch(httpError());
      state.loaded = true;

      if (res1?.error) {
        return;
      }

      state.contestInfo = res1.data.body.contestInfo;
      state.project = res1.data.body.project;

      const myContestAvailableAmt = state.contestInfo.investPossibleLimit - state.contestInfo.myContestInvestAmt;
      const myProjectAvailableAmt = state.contestInfo.projectPossibleLimit - state.contestInfo.myProjectInvestAmt;
      state.myAvailableAmt = myContestAvailableAmt >= myProjectAvailableAmt ? myProjectAvailableAmt : myContestAvailableAmt;

      if (state.myAvailableAmt <= 0) {
        store.commit("setSwingMessage", "투자 가능한 금액이 없습니다.");
        return router.replace({path: `/reward/${projectSeq}`});
      }

      const limit = state.myAvailableAmt / state.contestInfo.investMinimumLimit;

      if (!limit) {
        return;
      }

      for (let i = 1; i <= limit; i += 1) {
        state.amounts.push(state.contestInfo.investMinimumLimit * i);
      }

      state.form.expenseAmt = state.amounts[0];
    });

    const state = reactive({
      loaded: false,
      contestInfo: {
        currencyUnit: "원",
        investPossibleLimit: 0,
        projectPossibleLimit: 0,
        myContestInvestAmt: 0,
        myProjectInvestAmt: 0,
        investMinimumLimit: 0,
      },
      project: {
        projectName: "Wait a moment",
        builderName: "Wait",
      },
      myAvailableAmt: 0,
      amounts: [],
      mobile: "",
      form: {
        expenseAmt: 0,
        supportComment: "",
      }
    });

    let projectSeq;
    const defaultSupportComment = "응원합니다.";

    const submit = () => {
      const args = lib.getRenewed(state.form);

      if (state.project.mobileAuthFlag === "Y") {
        if (!lib.isValidMobileNum(state.mobile)) {
          document.getElementById(`${component.name}Mobile`).focus();
          return store.commit("setSwingMessage", "연락처가 비어있거나 유효하지 않습니다.");
        } else if (store.state.account.mobileAuthFlag !== "Y") {
          document.getElementById(`${component.name}Mobile`).focus();
          return store.commit("setSwingMessage", "연락처의 SMS 인증이 필요합니다.");
        }
      }

      if (args.supportComment && lib.getBytes(args.supportComment) > 750) {
        document.getElementById(`${component.name}CheerMessage`).focus();
        return store.commit("setSwingMessage", "응원 한마디의 내용이 너무 깁니다. 내용의 길이를 줄여주세요.");
      }

      if (!document.getElementById(`${component.name}Check`).checked) {
        return store.commit("setSwingMessage", "유의사항에 동의하셔야 투자 가능합니다.");
      }

      args.supportComment = args.supportComment || defaultSupportComment;

      http.post(`/api/reward/project/${projectSeq}/payments/mock`, args).then(({data}) => {
        router.push({path: `/mock/${projectSeq}/result/${data.body.investorSeq}`});
      }).catch(httpError());
    };

    return {component, state, submit, defaultSupportComment};
  }
});
</script>

<style lang="scss" scoped>
.reward {
  padding: $px36 $px15 $px40 $px15;

  .form-control {
    height: $formHeight;
  }

  > .container > .row {
    > .input {
      > .wrapper {
        position: relative;

        .back {
          display: inline-block;
          text-decoration: none;
          margin-bottom: $px15;

          .img {
            margin-right: $px7;
            width: $px16;
            height: $px16;
            vertical-align: middle;
          }

          span {
            vertical-align: middle;
          }

          &:hover {
            span {
              font-weight: 500;
            }
          }
        }

        > .thumbnail {
          position: absolute;
          top: $px15;
          right: 0;
          width: $px160;
          height: $px120;
          background-color: $colorBackground;
          border: $px1 solid #eee;
          border-radius: $px4;
        }

        header {
          padding-right: $px180;
          padding-bottom: $px35;
          margin-bottom: $px35;
          border-bottom: $px1 solid #eee;

          .title {
            display: inline-block;
            font-weight: 500;
            margin-bottom: $px8;
            text-decoration: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
          }
        }

        .parts {
          .part {
            padding-bottom: $px25;
            margin-bottom: $px25;
            position: relative;

            .side {
              position: absolute;
              top: $px5;
              right: 0;
              font-weight: normal;

              .form-check-input {
                margin-left: $px-18;
              }
            }

            > .subject {
              margin-bottom: $px13;
              font-weight: 500;

              label {
                margin: 0;
              }

              .desc {
                font-weight: normal;
                margin-left: $px7;
                color: #767676;
              }
            }

            > .content {
              .amount {
                .price {
                  float: left;

                  span, b {
                    display: inline-block;
                  }

                  span {
                    color: #666;
                  }

                  b {
                    margin-left: $px6;
                  }
                }

                .count {
                  float: right;
                  width: $px160;
                  text-align: right;
                  margin-top: $px-6;

                  label {
                    vertical-align: middle;
                    margin: 0 $px10 0 0;
                  }

                  input[type=number] {
                    display: inline-block;
                    padding-right: $px7;
                    width: $px70;
                    vertical-align: middle;
                  }
                }
              }

              .title {
                margin-top: $px13;
                background: $colorBackground;
                padding: $px11 $px15;
                border: $px1 solid #eee;
                border-radius: $px4;

                span {
                  display: inline-block;
                }
              }

              .option {
                margin-top: $px10;
              }

              .select {
                margin-top: $px20;
                position: relative;
              }
            }

            &.amount {
              padding-right: $px145;

              .subject .desc {
                margin-left: 0;
                margin-top: $px2;
              }

              .side {
                width: $px140;

                > span {
                  position: absolute;
                  top: $px8;
                  right: $px-20;
                }
              }
            }

            &.mobile {
              .subject .desc {
                margin-left: 0;
                margin-top: $px2;
              }
            }

            &.cheer {
              .content {
                padding-top: $px5;

                .input-group {
                  position: relative;

                  .img.profile {
                    width: $px40;
                    height: $px40;
                    margin-right: $px11;
                  }
                }

                textarea {
                  min-height: $px183;
                  border-radius: $px4;
                }
              }
            }

            &.mobile-auth {
              span {
                vertical-align: middle;

                &.img {
                  width: $px16;
                  height: $px16;
                  margin-right: $px3;
                }
              }
            }

            &.receive {
              .content {
                .form-group {
                  margin-bottom: $px25;
                  position: relative;
                }

                .default {
                  position: absolute;
                  top: 0;
                  right: 0;
                }
              }
            }
          }
        }
      }
    }

    > .pay {
      padding-left: $px66;

      > .wrapper {
        position: sticky;
        top: 0;
        max-width: $px470;
        margin: 0 auto;

        > .outer {
          border: $px1 solid #eee;
          border-radius: $px4;

          .inner {
            padding: $px25 $px25 $px10 $px25;

            .subject {
              margin-bottom: $px15;
              font-weight: 500;
            }

            .details {
              padding-bottom: $px25;

              table {
                th, td {
                  border: 0;
                  padding: $px5 0;
                }

                th {
                  font-weight: normal;
                }

                td {
                  text-align: right;
                }
              }
            }
          }

          .guide {
            border-top: $px1 solid #eee;
            padding: $px25 $px20;
            background: $colorBackground;

            .text {
              padding-bottom: $px18;

              > div {
                position: relative;
                padding-left: $px18;

                > a {
                  position: absolute;
                  top: 0;
                  left: 0;
                }

                &:not(:last-child) {
                  margin-bottom: $px4;
                }
              }
            }
          }
        }

        .action {
          padding-top: $px24;

          button {
            padding: $px26 $px15;
          }
        }
      }
    }
  }

  &.skeleton::v-deep {
    .form-control, button {
      @include skeleton;
    }

    .form-check {
      padding-left: 0;

      input {
        display: none
      }

      > div, > label {
        @include skeleton;
      }
    }

    > .container > .row {
      > .input {
        .back {
          @include skeleton;

          .img {
            visibility: hidden;
          }
        }

        header {
          .img, .title > span, .builder > span {
            @include skeleton;
          }
        }

        .parts .part {
          .subject {
            span, label {
              @include skeleton;
            }
          }

          .content {
            .amount {
              .price {
                min-height: $px30;
                min-width: $px120;
                @include skeleton;

                span, b {
                  display: none;
                }
              }

              .count {
                label, span, input {
                  @include skeleton;
                }
              }
            }

            .title {
              @include skeleton;
            }

            .option {
              label {
                @include skeleton;
              }
            }
          }

          &.amount .side span {
            @include skeleton;
          }

          &.cheer {
            .subject .form-check {
              padding-left: 0;

              input {
                display: none
              }

              > label {
                @include skeleton;
                min-width: $px49;
              }
            }

            .profile.img {
              @include skeleton;
            }
          }

          &.point .content .input-group .input-group-append {
            display: none;

            .times {
              display: none;
            }
          }

          &.payment .content ul li .img {
            visibility: hidden;
          }
        }
      }

      > .pay {
        > .wrapper {
          border-color: #eee;

          .inner {
            .subject {
              > span {
                @include skeleton;

                span, b {
                  visibility: hidden;
                }
              }
            }

            .details table {
              th, td span {
                @include skeleton;
              }
            }
          }

          .guide {
            a, b, span {
              @include skeleton;
            }
          }
        }
      }
    }
  }

  @media(min-height: 768px) {
    > .container > .row > .input > .wrapper .parts .part:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  @media(max-height: 767px) {
    > .container > .row > .pay > .wrapper {
      position: static;
    }
  }

  @media(max-width: 1199px) {
    > .container > .row > .pay {
      padding-left: $px30;
    }
  }

  @media(max-width: 991px) {
    padding: $px15 0;

    > .container > .row {
      > .input {
        > .wrapper {
          .thumbnail {
            top: $px30;
            width: $px120;
            height: $px72;
          }

          header {
            padding-right: $px125;

            .title {
              white-space: normal;
            }
          }

          .parts .part {
            padding-bottom: $px15;
            margin-bottom: $px15;

            > .subject .desc {
              display: block;
              margin-left: 0;
              margin-top: $px5;
            }

            > .side {
              top: $px4;
              right: 0;
            }

            &.amount {
              .side {
                padding-right: $px23;

                > span {
                  right: 0;
                }
              }
            }
          }
        }
      }

      > .pay {
        padding-top: $px15;
        padding-left: $px15;
      }
    }
  }
}
</style>